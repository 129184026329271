import { Controller } from "stimulus";
import FroalaEditor, { POWERED_BY } from "froala-editor";
import "froala-editor/js/plugins/fullscreen.min.js";
import "froala-editor/js/plugins/draggable.min.js";
import "froala-editor/js/plugins/emoticons.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/inline_class.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/paragraph_style.min.js";
import "froala-editor/js/plugins/quote.min.js";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/js/plugins/video.min.js";
import "froala-editor/js/plugins/url.min.js";
import "../../froala_audio.js";

export default class extends Controller {
  connect() {
    this.editor = new FroalaEditor(this.element, {
      key: "aLF3c1C10C5E6C2E3F2C-7wjhnxlnbmA3md1C-13mD6F5F4H4E1B9A8C3C4F5==",
      heightMin: `${this.element.dataset.height}px` || "450px",
      attribution: false,
      placeholderText: "Empieza a escribir...",
      height: this.element.dataset.height || 300,
      linkAttributes: {
        rel: "follow",
      },
      linkAlwaysBlank: true,
      linkAlwaysNoFollow: false,
      linkNoOpener: false,
      linkNoReferrer: false,
      linkText: true,
      pastePlain: true,
      toolbarButtons: {
        moreText: {
          buttons: [
            "bold",
            "italic",
            "underline",
            "strikeThrough",
            "subscript",
            "superscript",
            "fontFamily",
            "fontSize",
            "textColor",
            "backgroundColor",
            "inlineClass",
            "inlineStyle",
            "clearFormatting",
            "insertVideo",
          ],
        },
        moreParagraph: {
          buttons: [
            "alignLeft",
            "alignCenter",
            "formatOLSimple",
            "alignRight",
            "alignJustify",
            "formatOL",
            "formatUL",
            "paragraphFormat",
            "paragraphStyle",
            "inlineClass",
            "lineHeight",
            "outdent",
            "indent",
            "quote",
          ],
        },
        moreRich: {
          buttons: [
            "insertLink",
            "insertImage",
            "insertVideo",
            "insertTable",
            "emoticons",
            "fontAwesome",
            "specialCharacters",
            "embedly",
            "insertFile",
            "insertHR",
          ],
        },
        moreMisc: {
          buttons: [
            "undo",
            "redo",
            "fullscreen",
            "print",
            "getPDF",
            "spellChecker",
            "selectAll",
            "html",
            "help",
          ],
        },
      },
      inlineClasses: {
        "froala__testimonial-highlight": "Testimonial Highlight",
      },
      imageEditButtons: [
        "imageReplace",
        "imageAlign",
        "imageCaption",
        "imageRemove",
        "|",
        "imageLink",
        "linkOpen",
        "linkEdit",
        "linkRemove",
        "-",
        "imageDisplay",
        "imageStyle",
        "imageAlt",
        "imageSize",
      ],
      imageStyles: {
        "article__image--full-width": "full-width",
        "article__image--rounded": "rounded",
        "article__image--shadow": "shadow",
      },
      imageUploadURL: this.element.dataset.uploadUrl,
      imageUploadParam: this.element.dataset.uploadParam,
      imageUploadParams: {
        authenticity_token: Rails.csrfToken(),
        imageable_type: this.element.dataset.imageableType,
      },
      events: {
        "image.loaded": function ($img) {
          $img.removeAttr("image_data");
        },
      },
    });
  }
}
