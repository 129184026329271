import { Controller } from "stimulus";
import Chartkick from "chartkick";

export default class extends Controller {
  static targets = ["container"];
  static values = { id: String };

  connect() {
    this.chart = Chartkick.charts[this.idValue];
    this._setChartGradient();
  }

  _setChartGradient() {
    var container = this.element;
    var ctx = this.chart.ctx;
    var gradient = ctx.createLinearGradient(0, 0, 0, container.clientHeight);
    gradient.addColorStop(1, "rgba(126, 89, 240, 0)");
    gradient.addColorStop(0.2, "rgba(126, 89, 240, 0.5)");
    gradient.addColorStop(0, "rgba(126, 89, 240, 1)");
    this.chart.data.datasets.forEach(function (dataset) {
      dataset.backgroundColor = gradient;
    });
    this.chart.update();
  }
}
