import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["item", "content"];

  connect() {
    this._initializeIntersectionObserver();
    window.addEventListener("scroll", this._handleScroll.bind(this));
  }

  disconnect() {
    window.removeEventListener("scroll", this._handleScroll.bind(this));
  }

  markAsActive(event) {
    const headingId = event.target.dataset.id;
    this._highlightLink(headingId);
  }

  _initializeIntersectionObserver() {
    const options = {
      root: null,
      rootMargin: "0px 0px -100% 0px",
      threshold: 0,
    };

    this.observer = new IntersectionObserver(
      this._observerCallback.bind(this),
      options
    );

    const headings = this.contentTarget.querySelectorAll("h2");
    headings.forEach((heading) => {
      this.observer.observe(heading);
    });
  }

  _observerCallback(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const headingId = entry.target.id;

        this._highlightLink(headingId);
      }
    });
  }

  _highlightLink(id) {
    this.itemTargets.forEach((link) => {
      if (link.dataset.id === id) {
        link.classList.add("active");
      } else {
        link.classList.remove("active");
      }
    });
  }

  _handleScroll() {
    const headings = this.contentTarget.querySelectorAll("h2");
    const viewportTop = window.scrollY;

    for (let i = headings.length - 1; i >= 0; i--) {
      const heading = headings[i];
      const headingTop = heading.getBoundingClientRect().top + window.scrollY;

      if (headingTop <= viewportTop + 100) {
        // Adding 30 pixel tolerance to match the soft scroll
        const headingId = heading.id;
        this._highlightLink(headingId);
        break;
      }
    }
  }
}
