import FroalaEditor from "froala-editor";

(function (FroalaEditor) {
  // Add an option for your plugin.
  FroalaEditor.DEFAULTS = Object.assign(FroalaEditor.DEFAULTS, {
    myOption: false,
  });

  // Define the plugin.
  // The editor parameter is the current instance.
  FroalaEditor.PLUGINS.myPlugin = function (editor) {
    // Private variable visible only inside the plugin scope.
    var private_var = "Froala Audio";

    // The start point for your plugin.
    function _init() {
      // You can access any option from documentation or your custom options.
      // Call any method from documentation.
      // editor.methodName(params);
      // You can listen to any event from documentation.
      // editor.events.add('contentChanged', function (params) {});
    }

    // Expose public methods. If _init is not public then the plugin won't be initialized.
    // Public method can be accessed through the editor API:
    // editor.myPlugin.publicMethod();
  };
})(FroalaEditor);
