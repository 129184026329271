import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modalContainer", "modal", "backgroundOverlay"];

  connect() {
    this.closeModal(); // Ensure modal is closed on load.
  }

  handleModalClick(e) {
    e.preventDefault();
    this.toggleModal();
  }

  handleKeyup(e) {
    if (e.key === "Escape") {
      this.closeModal();
    }
  }

  toggleModal() {
    if (this._isModalOpen()) {
      this.closeModal();
    } else {
      this.showModal();
    }
  }

  closeModal() {
    this.modalContainerTarget.classList.add("hidden");
    this.modalTarget.classList.replace("translate-y-0", "translate-y-full");
    this.modalTarget.classList.replace("opacity-100", "opacity-0");
    this._hideBackgroundOverlay();
  }

  showModal() {
    this.modalContainerTarget.classList.remove("hidden");
    this.modalTarget.classList.replace("translate-y-full", "translate-y-0");
    this.modalTarget.classList.replace("opacity-0", "opacity-100");
    this._showBackgroundOverlay();
  }

  _showBackgroundOverlay() {
    this.backgroundOverlayTarget.classList.replace("opacity-0", "opacity-75");
  }

  _hideBackgroundOverlay() {
    this.backgroundOverlayTarget.classList.replace("opacity-75", "opacity-0");
  }

  _isModalOpen() {
    return !this.modalContainerTarget.classList.contains("hidden");
  }
}
